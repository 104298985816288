html,
body {
    height: 100vh;
    width: 100vw;
}

.calendar-screen {
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.rbc-calendar {
    height: 100%;
}

/* Modal */
.ReactModalPortal > div {
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
    z-index: 999;
}

.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 620px;
    max-width: 500px;
    outline: none;
    padding: 10px;
}

/* Datepicker */
.react-datetime-picker input:focus {
    outline: none;
}
.react-datetime-picker__wrapper {
    border: 0 solid gray !important;
}


/* FAB */
.fab {
    border-radius: 100%;
    font-size: 30px;
    padding: 15px;
    position: fixed;
    bottom: 25px;
    right: 25px;
}

.fab-danger {
    position: fixed;
    bottom: 25px;
    left: 25px;
}
